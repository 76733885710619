$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: $screen-xl) {
  .ant-modal {
    max-width: 1000px !important;
  }
}

@media only screen and (max-width: $screen-lg) {
  .ant-modal {
    max-width: 900px !important;
  }
}

@media only screen and (max-width: $screen-md) {
  .ant-modal {
    max-width: 100% !important;
  }

  // OHIF
  .ToolbarRow {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: $screen-xs) {
}

@media only screen and (max-width: 320px) {
}

