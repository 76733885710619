
.dx-scheduler-appointment-title {
  white-space: pre-line !important;
  max-height: 60px !important;
}

.dx-scheduler-appointment {
  padding: 0 !important;
}

.dx-scheduler-agenda .dx-scheduler-appointment-title {
  line-height: 13px !important;
  font-size: 12px !important;
}


.dx-scrollable-native.dx-scrollable-vertical, .dx-scrollable-native.dx-scrollable-vertical > .dx-scrollable-wrapper > .dx-scrollable-container, .dx-scrollable-native.dx-scrollable-vertical > div > .dx-scrollable-wrapper > .dx-scrollable-container {
  touch-action: manipulation !important;
}

@media only screen and (max-width: 1224px) {
  .dx-scheduler-view-switcher.dx-dropdownmenu.dx-button {
    position: absolute !important;
    left: 10px !important;
    right: initial !important;
  }

  .dx-scheduler-navigator {
    position: absolute !important;
    left: 50px !important;
    right: initial !important;
  }

  .dx-scheduler-header {
    position: fixed !important;
  }

  .dx-scheduler-work-space {
    padding-top: 56px;
    margin-top: 0px !important;
  }
}


//.dx-scheduler-appointment-recurrence-icon {
//  display: none !important;
//  visibility: hidden !important;
//}
//
//.dx-scheduler-appointment-recurrence .dx-scheduler-appointment-content {
//  padding: 5px 5px 5px 7px !important;
//}